import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface ICrudOperations<T> {
  getAll(name?: string): Observable<T[]>;
  getById(options: any, name?: string): Observable<T>;
  getAllById(options: any, name?: string): Observable<T[]>;
  create(dto: T, name?: string): Observable<T>;
  createAny(dto: any, name?: string): Observable<any>;
  update(id: any, dto: T, name?: string): Observable<T>;
  deleteById(id: any, name?: string): Observable<any>;
}

export class BaseApiService<Type> implements ICrudOperations<Type> {
  //#region 'Variables'
  protected readonly _apiEndpoint: string;
  //#endregion

  //#region 'Angular Life Cycle'
  constructor(
    protected _http: HttpClient,
    protected _url: string
  ) {
    this._apiEndpoint = `${environment.apiEndpoint}/${_url}`;
  }
  //#endregion

  //#region 'Get'
  public getEndpoint(): string {
    return this._apiEndpoint;
  }

  public getAll(name?: string): Observable<Type[]> {
    return this._http.get<Type[]>(`${this._apiEndpoint}${name}`);
  }

  public getById(options: any, name?: string): Observable<Type> {
    return this._http.get<Type>(`${this._apiEndpoint}${name}`, { params: options });
  }

  public getAllById(options: any, name?: string): Observable<Type[]> {
    return this._http.get<Type[]>(`${this._apiEndpoint}${name}`, { params: options });
  }
  //#endregion 'Get'

  //#region 'Create'
  public create(dto: Type, name?: string): Observable<Type> {
    return this._http.post<Type>(`${this._apiEndpoint}${name}`, dto);
  }

  public createAny(dto: any, name?: string): Observable<any> {
    return this._http.post<any>(`${this._apiEndpoint}${name}`, dto);
  }

  // Add the 'queryParams' optional parameter of type 'any'
  public createAnyWithQueryParams(dto: any, name?: string, numbers?: number[]): Observable<any> {
    let params = new HttpParams();

    if (numbers) {
      numbers.forEach((number, index) => {
        params = params.append(`numbers[${index}]`, number.toString());
      });
    }

    return this._http.post<any>(`${this._apiEndpoint}${name}`, dto, { params });
  }

  public createAnyWithQueryOptions(dto: any, name?: string, options?: any): Observable<any> {
    let params = new HttpParams();

    if (options) {
      params = options;
    }

    return this._http.post<any>(`${this._apiEndpoint}${name}`, dto, { params });
  }
  //#endregion 'Create'

  //#region 'Update'
  public update(_options: any, dto: Partial<Type>, name?: string): Observable<Type> {
    return this._http.put<Type>(`${this._apiEndpoint}${name}`, dto);
  }

  //#endregion 'Update'

  //#region 'Delete'
  public deleteById(_options: any, name?: string): Observable<any> {
    return this._http.delete(`${this._apiEndpoint}${name}`, { responseType: 'text' });
  }
  //#endregion 'Delete'

  //#region 'Other'
  //#endregion
}
