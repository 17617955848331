import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class BulkUploadValidationApiService extends BaseApiService<BulkUploadValidationApiService> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'BulkUploadValidation');
  }
  //#endregion

  //#region 'Get'
  public GetInvalidRowsAndColumnsAsync(tableEntries: string[][], refineryId: number): Observable<any> {
    const body = { tableEntries };
    const options = { refineryId };
    let res = this.createAnyWithQueryOptions(body, '/GetBulkUploadValidationAsync', options);
    return res;
  }

  public GetBulkUploadIdsFromTable(tableEntries: string[][], refineryId: number): Observable<any> {
    const body = { tableEntries };
    const options = { refineryId };
    return this.createAnyWithQueryOptions(body, '/GetBulkUploadIdsFromTable', options);
  }
}
