import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService, NotificationToast } from '../../shared/notification';
import { EditMultipleQaTypesComponent } from './edit-multiple-qa-types/edit-multiple-qa-types.component';
import { EditMultipleProcessesComponent } from './edit-multiple-processes/edit-multiple-processes.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';

import {
  DropdownService,
  ExportToExcelService,
  KeypressValidationService,
  LoadingIndicatorService,
  WeldLogService,
  WeldLogViewService,
  RefineryService,
  UserInfoService
} from '../../../data/services';
import { TableHeaders, WeldLog, WeldLogParams } from '../../../core/models';
import { WeldLogTable } from '../../../data/models';
import { MenuItem, MessageService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-weld-log',
  templateUrl: './weld-log.component.html',
  styleUrls: ['./weld-log.component.scss'],
  providers: [WeldLogViewService, WeldLogService]
})
export class WeldLogComponent implements OnInit {
  //#region 'Variables'

  // User refinery
  private refineryId: number;
  private userId: string;
  public bulkrefineryId: number;
  public bulkrefineryName: string;

  //add/edit/view form
  public draftBtn = '';
  public actionLog = 0;
  public dialogHeader = '';
  public deleteDialogHeader = '';
  public qaTypeDialogHeader = '';
  public qaDateDialogHeader = '';
  public processDialogHeader = '';
  public acceptRejectDialogHeader = '';
  public dialogSubmit = '';
  public hideDraftBtn = false;
  public hideActionBtns = false;
  public hideForContinuity = false;
  public hideRequired = false;
  public formDisabled = false;
  public isViewEdit = false;
  public displayLogForm = false;
  public requiredFieldsLog = 0;
  private bypassWarning = false;

  //clone
  public displayCloneDialog = false;
  public logToBeCloned: any;
  public amountToClone: number;

  //Create Repair
  public displayRepairDialog = false;
  public logToBeRepaired: any;
  public newWeldModifierText: string;

  //customize view
  private userLogView: any;
  public displayCustomizeTableView = false;

  //display
  public displayDeleteDialog = false;
  public reasonForDeletion = '';

  //excel export
  public activeFilterParams = new WeldLogParams(); //Used only for the excel sheet. Exists so that we don't use wrong filter params if user changed them but never hit the filter button
  public exportColumns: any[];

  //multiple log edits
  public selectedLogs: WeldLog[];
  public displayEditMultipleLogsForm = false;
  public multipleLogsResult: number; // Api call result cases >> 1: All Success, 2: All Fails, 3: Some Passes & Some Fails
  public contextMenuItems: MenuItem[];
  public confirmType: string;

  public displayEditQATypeDialog = false;
  public displayEditQADateDialog = false;
  public editQATypeText = '';

  public displayEditProcessDialog = false;
  public displayEditAcceptRejectDialog = false;

  //send NDE
  public displaySendNDEForm = false;

  //permissions
  public readonly PERMISSIONS = JSON.parse(localStorage.getItem('user'));
  public editLogPermission: boolean;
  public deleteLogPermission: boolean;
  public createLogPermission: boolean;

  // Weld Log table
  public filterParams = new WeldLogParams();
  public inactiveFilters = false;
  public currentLog = new WeldLog();
  public weldLogData: WeldLogTable[];

  // QA Types
  public editMultipleQATypeVals: any[];
  @ViewChild(EditMultipleQaTypesComponent) editMultipleQATypesComponent!: EditMultipleQaTypesComponent;

  // Processes
  public editMultipleProcessesVals: any[];
  public confirmedSubmissions: any[] = [];
  public skippedSubmissions: any[] = [];
  public pendingSubmissions: any[] = [];
  @ViewChild(EditMultipleProcessesComponent) editMultipleProcessesComponent!: EditMultipleProcessesComponent;

  //Bulk Upload
  public displayBulkUploadForm = false;
  public bulkUploadBtnDisabled = false;
  public bulkUploadBtnTextWhenDisabled = ' (Coming Soon ??)';
  @ViewChild(BulkUploadComponent) bulkUpload: BulkUploadComponent;

  // Headers
  public defaultHeaders = [];
  public disabledHeaders = [];
  public optionalHeaders = [];
  private disabledHeadersIndex = 0;
  //#endregion

  //#region 'Angular Life Cycle'
  constructor(
    private weldLogView: WeldLogViewService,
    public dropdownService: DropdownService,
    private logService: WeldLogService,
    private _notifSrv: NotificationService,
    private _notify: MessageService,
    private _loader: LoadingIndicatorService,
    public keyValidation: KeypressValidationService,
    private _expExcel: ExportToExcelService,
    private _srvRefinery: RefineryService,
    private _srvUserInfo: UserInfoService
  ) {}

  ngOnInit() {
    this._loader.show();
    const tempDate = new Date();
    this.filterParams.weldtodate = moment(tempDate).format('YYYY-MM-DD');
    this.filterParams.weldfromdate = moment(tempDate).subtract(5, 'M').format('YYYY-MM-DD');
    this.activeFilterParams = JSON.parse(JSON.stringify(this.filterParams));
    let headers: TableHeaders[] = JSON.parse(localStorage.getItem('tableHeaders'));
    setTimeout(() => {
      this.getWeldLogView(); // load user's customize table view settings
      this.getRequiredFieldsViewByRefinery();
      this.getDropdownsData();
      this.getWeldLogsData();
      this.checkPermissions();
    }, 2000);

    // load table headers
    this.loadDefaultHeaders(headers);
    this.loadOtherHeaders(headers);
  }
  //#endregion

  public setparams(): void {
    const USER = JSON.parse(localStorage.getItem('user'));
    if (USER) {
      this.userId = USER.userId;
      this.refineryId = USER.refineryId;
      this.bulkrefineryId = USER.refineryId;
      this.bulkrefineryName = USER.refineryName;
    }
  }

  //#region 'Load Table Headers'
  private loadDefaultHeaders(headers: TableHeaders[]) {
    const WIDTH_COEFFICIENT = 190;
    let defaultHeaderRes = headers.filter(x => x.componentName == 'weld-log' && x.arrayName == 'defaultHeaders');
    for (let i = 0; i < defaultHeaderRes.length; i++) {
      this.defaultHeaders.push({
        field: defaultHeaderRes[i].field,
        header: defaultHeaderRes[i].header,
        width: defaultHeaderRes[i].field.length + WIDTH_COEFFICIENT + 'px',
        excelWidth: Math.floor((defaultHeaderRes[i].field.length + WIDTH_COEFFICIENT) / 8)
      });
    }
  }

  private loadOtherHeaders(headers: TableHeaders[]) {
    const WIDTH_COEFFICIENT = 190;
    let disabledHeaderRes = headers.filter(x => x.componentName == 'weld-log' && x.arrayName == 'disabledHeaders');
    for (let i = 0; i < disabledHeaderRes.length; i++) {
      let header = {
        field: disabledHeaderRes[i].field,
        header: disabledHeaderRes[i].header,
        width: disabledHeaderRes[i].field.length + WIDTH_COEFFICIENT + 'px',
        excelWidth: Math.floor((disabledHeaderRes[i].field.length + WIDTH_COEFFICIENT) / 8),
        index: this.disabledHeadersIndex
      };

      // Add the QA Type Date Sub Field for the appropriate headers
      if (disabledHeaderRes[i].qaTypeDateSubField != null) {
        header['qaTypeDate'] = disabledHeaderRes[i].qaTypeDateSubField;
      }
      this.disabledHeaders.push(header);
      this.disabledHeadersIndex++;
    }
  }
  //#endregion

  //#region 'Load'
  private checkPermissions() {
    this.editLogPermission =
      this.PERMISSIONS &&
      (this.PERMISSIONS.permissions.includes('system admin') ||
        this.PERMISSIONS.permissions.includes('update weld log'));
    this.deleteLogPermission =
      this.PERMISSIONS &&
      (this.PERMISSIONS.permissions.includes('system admin') ||
        this.PERMISSIONS.permissions.includes('delete weld log'));
    this.createLogPermission =
      this.PERMISSIONS &&
      (this.PERMISSIONS.permissions.includes('system admin') ||
        this.PERMISSIONS.permissions.includes('add new weld log'));
  }

  private getDropdownsData() {
    if (this.dropdownService.userRefinery) {
      this.dropdownService.loadWeldType();
      this.dropdownService.GetDivisions();
      this.dropdownService.GetUnits();
      this.dropdownService.GetWeldGroups();
      this.dropdownService.GetWeldProcesses();
      this.dropdownService.GetWPS();
      this.dropdownService.GetFillerMat();
      this.dropdownService.GetJointTypes();
      this.dropdownService.GetRTPercent();
      this.dropdownService.GetStampAllocations();
      this.dropdownService.GetMaterial();
      this.dropdownService.GetSchedule();
      this.dropdownService.GetSize();
      this.dropdownService.GetQAType();
      this.dropdownService.GetWeldTypeIdentifier();
    } else {
      this.dropdownService.setRefineryId();
      setTimeout(() => {
        this.getDropdownsData();
      }, 1000);
    }
  }

  public getUnits() {
    if (this.filterParams.divisionid == null) {
      this.dropdownService.GetUnits();
    } else {
      this.filterParams.unitid = null;
      this.dropdownService.GetUnitsByDivisionAsync(this.filterParams.divisionid);
    }
  }

  public getWeldLogsData() {
    this.logService.GetTableWeldLogs(this.filterParams).then(res => {
      this.weldLogData = res;
      setTimeout(() => {
        this._loader.hide();
      }, 200);
    });
  }

  public verifyQADates(log: WeldLog) {
    /* checks if weld log is production, log, and has no QA dates. If so, then highlight EWO and Weld # */

    // Exit early if not production or if the log status is draft
    if (log.weldType != 1 || log.logEntryStatus) return false;

    // Exit early if weldLogQAs is null or has no items
    let weldLogQAArray = log.weldLogQAs;
    if (!weldLogQAArray || weldLogQAArray.length == 0) return false;

    // Check each item in the array for a valid qaDate
    for (let qa of weldLogQAArray) {
      if (!qa.qaDate) return true;
    }

    // If all qaDates are valid, return false
    return false;
  }

  private async getWeldLogView() {
    this.userLogView = await this.weldLogView.GetWeldLogView();
    this.setupOptionalHeaders();
  }

  public async getRequiredFieldsViewByRefinery() {
    this.setparams();
    const refinery = await this._srvRefinery.GetRefineriesAsyncById(Number(this.refineryId));
    if (refinery[0].refineryCode === 'MCBU') {
      this.requiredFieldsLog = 1;
    } else {
      this.requiredFieldsLog = 0;
    }
  }

  private setupOptionalHeaders() {
    if (this.userLogView != null) {
      // don't need to setup the headers if user has no saved settings
      const regex = /\d+/g;
      const matches = this.userLogView.match(regex);

      if (matches != null) {
        // users can have empty settings, don't try to fill if empty
        let tempOp = [];
        let tempDis = [];
        for (let match of matches) {
          /* tslint:disable-next-line */
          const temp = this.disabledHeaders.find(x => x.index == Number(match));
          if (tempOp.length === 0 && tempDis.length === 0) {
            tempOp = [...this.optionalHeaders, temp];
            tempDis = this.disabledHeaders.filter(x => x !== temp);
          } else {
            tempOp = [...tempOp, temp];
            tempDis = tempDis.filter(x => x !== temp);
          }
        }
        this.optionalHeaders = tempOp;
        this.disabledHeaders = tempDis;
      }
    }
  }
  //#endregion

  //#region 'General Methods'
  public submitFilter() {
    this.activeFilterParams = JSON.parse(JSON.stringify(this.filterParams));
    if (Date.parse(this.filterParams.weldfromdate) > Date.parse(this.filterParams.weldtodate)) {
      this.showNotification(
        new NotificationToast('error', 'Error: cannot fetch logs, invalid date selection', '', 'error', 6000)
      );
    } else {
      this.getWeldLogsData();
      this.checkForInactiveFilters();
    }
  }

  public checkForInactiveFilters() {
    this.inactiveFilters = false;
    for (const filter in this.filterParams) {
      if (this.filterParams[filter] != this.activeFilterParams[filter]) {
        this.inactiveFilters = true;
        break;
      }
    }
  }

  public setContextMenu(event: any) {
    this.contextMenuItems = [{ label: 'Must first make a row selection.', icon: 'pi pi-times', disabled: true }];

    if (!this.selectedLogs || this.selectedLogs.length === 0) {
      return;
    }

    this.contextMenuItems = [{ label: 'Right-click on a highlighted row. ', icon: 'pi pi-times', disabled: true }];

    if (!this.selectedLogs.some(log => log.weldLogId === event.data.weldLogId)) {
      return;
    }

    if (this.selectedLogs.length > 1) {
      this.setContextMenuForMultipleLogs();
    } else {
      this.setContextMenuForSingleLog();
    }
  }

  private setContextMenuForSingleLog() {
    this.contextMenuItems = [
      { label: 'View', icon: 'pi pi-eye', command: () => this.viewLogOpen(this.selectedLogs[0]) },
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => this.editLogOpen(this.selectedLogs[0]),
        disabled: !this.editLogPermission
      },
      {
        label: 'Send NDEs',
        icon: 'pi pi-send',
        command: () => this.openSendNDEForm(),
        disabled: !this.editLogPermission || this.selectedLogs.some(log => log.entryStatus === 'Draft')
      },

      {
        label: 'Clone',
        icon: 'pi pi-clone',
        command: () => this.openCloneDialog(this.selectedLogs[0]),
        disabled: !this.createLogPermission
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => this.openDeleteDialog(this.selectedLogs[0]),
        disabled: !this.deleteLogPermission
      },
      {
        label: 'Repair',
        icon: 'pi pi-cog',
        command: () => this.openRepairWeldLogDialog(this.selectedLogs[0]),
        disabled: !this.createLogPermission
      }
    ];
  }

  private setContextMenuForMultipleLogs() {
    const prodLogs = this.selectedLogs.filter(log => log.weldType !== 2);
    const continuityFound = this.selectedLogs.some(log => log.weldType == 2);
    const editCommand = continuityFound ? () => this.showConfirm('edit') : () => this.openEditMultipleLogsForm();
    const deleteCommand = continuityFound ? () => this.showConfirm('delete') : () => this.openDeleteDialog();
    const sendNDECommand = continuityFound ? () => this.showConfirm('sendNDE') : () => this.openSendNDEForm();

    this.contextMenuItems = [
      {
        label: 'Edit Multiple Logs',
        icon: 'pi pi-pencil',
        command: editCommand,
        disabled: !this.editLogPermission || prodLogs.length < 2
      },
      {
        label: 'Send NDEs',
        icon: 'pi pi-send',
        command: sendNDECommand,
        disabled:
          !this.editLogPermission || prodLogs.length < 2 || this.selectedLogs.some(log => log.entryStatus === 'Draft')
      },
      {
        label: 'Delete Multiple Logs',
        icon: 'pi pi-trash',
        command: deleteCommand,
        disabled: !this.deleteLogPermission
      },
      {
        label: 'Add QA Types',
        icon: 'pi pi-plus-circle',
        command: () => this.openQATypeDialog(),
        disabled: continuityFound || !this.editLogPermission
      },
      {
        label: 'Add QA Dates',
        icon: 'pi pi-calendar-plus',
        command: () => this.openQADateDialog(),
        disabled: continuityFound || !this.editLogPermission
      },
      {
        label: 'Add Welder',
        icon: 'pi pi-plus-circle',
        command: () => this.openProcessDialog(),
        disabled: continuityFound || !this.editLogPermission
      },
      {
        label: 'Add Accept/Reject',
        icon: 'pi pi-plus-circle',
        command: () => this.openAcceptRejectDialog(),
        disabled: continuityFound || !this.editLogPermission
      }
    ];
  }

  public logSuccessOrFail(success: boolean) {
    if (success) {
      this.clearLogForm();
      this.displayLogForm = false;
      this._loader.hide();
      this.showNotification(new NotificationToast('success', 'Log successfully submitted', '', 'success'));
      this.getWeldLogsData();
    } else {
      this._loader.hide();
      this.showNotification(new NotificationToast('error', 'Error sending log', '', 'error', 6000));
    }
  }

  //Api call result cases >> 1: All Success, 2: All Fails, 3: Some Passes & Some Fails
  public processMultipleLogsResults(res: string): number {
    let obj = JSON.parse(res);
    if (Object.values(obj).indexOf('false') == -1) {
      this.multipleLogsResult = 1;
    } else if (Object.values(obj).indexOf('True') == -1) {
      this.multipleLogsResult = 2;
    } else if (Object.values(obj).indexOf('True') > -1 && Object.values(obj).indexOf('false') > -1) {
      this.multipleLogsResult = 3;
    }
    return this.multipleLogsResult;
  }

  public multipleLogsSuccessOrFail(result: string, action: string) {
    let success = this.processMultipleLogsResults(result);
    switch (success) {
      case 1:
        this.clearLogForm();
        let message = action === 'update' ? 'Logs successfully updated' : 'Logs successfully added';
        this.displayEditMultipleLogsForm = false;
        this.displayBulkUploadForm = false;
        this._loader.hide();
        this.showNotification(new NotificationToast('success', message, '', 'success', 6000));
        this.getWeldLogsData();
        break;

      case 2:
        this._loader.hide();
        let error = action === 'update' ? 'Error updating logs' : 'Error adding logs';
        this.showNotification(new NotificationToast('error', error, '', 'error', 6000));
        break;

      case 3:
        let failedWeldNos = '';
        let numOfFails = 0;
        let obj = JSON.parse(result);
        for (let key of Object.keys(obj)) {
          if (obj[key] === 'false') {
            failedWeldNos = `${failedWeldNos}${key}, `;
            numOfFails += 1;
          }
        }

        let failed = action === 'update' ? 'Error updating the following' : 'Error adding the following';
        failedWeldNos = failedWeldNos.slice(0, -2);
        this.clearLogForm();
        this.displayEditMultipleLogsForm = false;
        this.displayBulkUploadForm = false;
        this._loader.hide();
        this._notify.add({
          severity: 'warn',
          summary: `${failed} (${numOfFails}) logs`,
          detail: `Weld #'s: ${failedWeldNos}`,
          key: 'error',
          sticky: true
        });
        this.getWeldLogsData();
        break;
    }
  }
  //#endregion

  //#region 'Add/Edit/View Weld Log(s)'
  public newLogOpen() {
    this.actionLog = 0;
    this.isViewEdit = false;
    this.clearLogForm();
    this.hideDraftBtn = false;
    this.hideActionBtns = false;
    this.draftBtn = 'Save as Draft';
    this.dialogHeader = 'Add New Weld Log';
    this.dialogSubmit = 'Submit Weld Log';
    this.displayLogForm = true;
    this.hideForContinuity = false;
    this.displayCloneDialog = false;
  }

  public editLogOpen(existingLog: WeldLog) {
    this.isViewEdit = true;
    this.currentLog = existingLog;
    this.dialogHeader = 'Edit Weld Log';
    this.dialogSubmit = 'Update Weld Log';
    this.draftBtn = existingLog.entryStatus === 'Draft' ? 'Update Draft' : 'Save as Draft';
    this.hideDraftBtn = existingLog.entryStatus === 'Log' || existingLog.weldType === 2 ? true : false;
    this.hideForContinuity = existingLog.weldType === 2 ? true : false;
    this.hideActionBtns = false;
    this.actionLog = 1;
    this.displayLogForm = true;
  }

  public viewLogOpen(existingLog: WeldLog) {
    this.isViewEdit = true;
    this.currentLog = existingLog;
    this.dialogHeader = 'View Weld Log';
    this.dialogSubmit = 'Update Weld Log';
    this.hideActionBtns = true;
    this.hideForContinuity = existingLog.weldType === 2 ? true : false;
    this.actionLog = 2;
    this.displayLogForm = true;
  }

  private clearLogForm() {
    this.currentLog = new WeldLog();
    this.selectedLogs = [];
  }

  public closeLogForm() {
    this.displayLogForm = false;
  }

  public openEditMultipleLogsForm() {
    this.displayEditMultipleLogsForm = true;
  }

  public closeEditMultipleLogsForm() {
    this.displayEditMultipleLogsForm = false;
  }
  //#endregion

  public openSendNDEForm() {
    this.displaySendNDEForm = true;
  }

  public closeSendNDEForm() {
    this.displaySendNDEForm = false;
  }

  //#region 'Bulk Upload Logs'
  public uploadBulkLogs() {
    this.actionLog = 0;
    this.isViewEdit = false;
    this.clearLogForm();
    this.hideDraftBtn = false;
    this.hideActionBtns = false;
    this.displayBulkUploadForm = true;
    this.hideForContinuity = false;
    this.displayCloneDialog = false;
  }

  public openCloseBulkUploadDialog() {
    this.displayBulkUploadForm = !this.displayBulkUploadForm;
  }

  public clearBulkUploadData() {
    this.bulkUpload.clearData();
  }

  //#endregion

  //#region 'Customize Table'
  public openCustomizeTableView() {
    this.displayCustomizeTableView = true;
  }

  public updateLogView(event: boolean) {
    this.displayCustomizeTableView = false;
    if (event === true) {
      let headerString = '';
      for (let header of this.optionalHeaders) {
        if (headerString.length > 0) {
          headerString = headerString.concat(',', header.index.toString());
        } else {
          headerString = header.index.toString();
        }
      }
      this.weldLogView.UpdateWeldLogView(headerString);
      this.showNotification(new NotificationToast('success', 'Updated optional columns', '', 'success'));
    }
  }
  //#endregion

  //#region 'Clone Dialog'
  public openCloneDialog(log: any) {
    this.logToBeCloned = log;
    this.displayCloneDialog = true;
  }

  public closeCloneDialog() {
    this.amountToClone = null;
    this.displayCloneDialog = false;
  }

  public async cloneWeldLog(): Promise<void> {
    if (
      this.amountToClone == undefined ||
      this.amountToClone == null ||
      this.amountToClone <= 0 ||
      this.amountToClone > 20
    ) {
      this.showNotification(
        new NotificationToast('error', 'The number of clones should be between 1 to 20', '', 'error', 4000)
      );
      setTimeout(() => {}, 2000);
    } else {
      this._loader.show();
      const success = await this.logService.CloneWeldLogAsync(this.logToBeCloned.original, this.amountToClone);
      if (success) {
        this.showNotification(new NotificationToast('success', 'Log successfully cloned', '', 'success'));
        setTimeout(() => {
          this.closeCloneDialog();
          this.getWeldLogsData();
        }, 2000);
      } else {
        this.showNotification(new NotificationToast('error', 'Error: failed to clone log', '', 'error', 6000));
        this._loader.hide();
      }
    }
  }
  //#endregion

  //#region 'Create Repair Log dialog'
  public openRepairWeldLogDialog(log: any) {
    this.logToBeRepaired = log;
    this.displayRepairDialog = true;
  }

  public closeRepairWeldLogDialog() {
    this.displayRepairDialog = false;
  }

  public async repairWeldLog(): Promise<void> {
    if (this.newWeldModifierText == undefined || this.newWeldModifierText == null || this.newWeldModifierText == '') {
      this.showNotification(
        new NotificationToast('error', 'The new weld modifier text cannot be empty', '', 'error', 4000)
      );
      setTimeout(() => {}, 2000);
      return;
    }

    this._loader.show();
    this.logToBeRepaired.original.weldModifier = this.newWeldModifierText;
    this.logToBeRepaired.acceptReject = false; //might have to create a new variable in the model file called "rtacceptedRejected"

    const success = await this.logService.CloneWeldLogAsync(this.logToBeRepaired.original, 1, true);
    if (success) {
      this.showNotification(new NotificationToast('success', 'Successfully created a repair log', '', 'success'));
      setTimeout(() => {
        this.closeRepairWeldLogDialog();
        this.getWeldLogsData();
      }, 2000);
    } else {
      this.showNotification(new NotificationToast('error', 'Error: failed to create a repair log', '', 'error', 6000));
      this._loader.hide();
    }

    this.newWeldModifierText = '';
  }
  //#endregion

  //#region 'Delete Log(s)'
  public openDeleteDialog(log?: WeldLog) {
    if (log) {
      this.deleteDialogHeader = 'Delete Weld Log';
      this.currentLog = log;
    } else {
      this.deleteDialogHeader = 'Delete Multiple Weld Logs';
    }
    this.reasonForDeletion = '';
    this.displayDeleteDialog = true;
  }

  public closeDeleteDialog() {
    this.displayDeleteDialog = false;
  }

  public async deleteLog() {
    if (this.reasonForDeletion == '' || this.reasonForDeletion == null) {
      this.showNotification(
        new NotificationToast('error', 'Error: reason for deletion is required', '', 'error', 6000)
      );
    } else {
      this._loader.show();
      const success = await this.logService.DeleteWeldLog(this.currentLog, this.reasonForDeletion);
      this.currentLog = undefined;
      if (success) {
        setTimeout(() => {
          this.displayLogForm = false;
          this.displayDeleteDialog = false;
          this.clearLogForm();
          this.getWeldLogsData();
          this._loader.hide();
        }, 2000);
        this.showNotification(new NotificationToast('success', 'Log successfully deleted', '', 'success'));
      } else {
        this._loader.hide();
        this.showNotification(new NotificationToast('error', 'Error: failed to delete log', '', 'error', 6000));
      }
    }
  }

  public async deleteMultipleLogs() {
    if (this.reasonForDeletion == '' || this.reasonForDeletion == null) {
      this.showNotification(
        new NotificationToast('error', 'Error: reason for deletion is required', '', 'error', 6000)
      );
    } else {
      this._loader.show();
      const success = await this.logService.DeleteMultipleWeldLogs(this.selectedLogs, this.reasonForDeletion);
      let successResults = this.processMultipleLogsResults(success);
      this.selectedLogs = undefined;
      switch (successResults) {
        case 1:
          setTimeout(() => {
            this.displayEditMultipleLogsForm = false;
            this.displayDeleteDialog = false;
            this.clearLogForm();
            this.getWeldLogsData();
            this._loader.hide();
          }, 2000);
          this.showNotification(new NotificationToast('success', 'Log successfully deleted', '', 'success', 6000));
          break;
        case 2:
          this._loader.hide();
          this.showNotification(new NotificationToast('error', 'Error: failed to delete logs', '', 'error', 6000));
          break;
        case 3:
          let failedWeldNos = '';
          let numOfFails = 0;
          let obj = JSON.parse(success);
          for (let key of Object.keys(obj)) {
            if (obj[key] === 'false') {
              failedWeldNos = `${failedWeldNos}${key}, `;
              numOfFails += 1;
            }
          }
          failedWeldNos = failedWeldNos.slice(0, -2);
          this.clearLogForm();
          this.displayEditMultipleLogsForm = false;
          this._loader.hide();
          this._notify.add({
            severity: 'warn',
            summary: `Error deleting the following (${numOfFails}) logs`,
            detail: `Weld #'s: ${failedWeldNos}`,
            key: 'error',
            sticky: true
          });
          this.getWeldLogsData();
          break;
      }
    }
  }
  //#endregion

  //#region 'Add Multiple QA Type(s)'
  public openQATypeDialog(log?: WeldLog) {
    if (log) {
      this.qaTypeDialogHeader = 'Add QA Types to Multiple Weld Logs';
      this.currentLog = log;
    } else {
      this.qaTypeDialogHeader = 'Add QA Types to Multiple Weld Logs';
    }
    this.displayEditQATypeDialog = true;
  }

  public closeQATypeDialog() {
    this.displayEditQATypeDialog = false;
  }
  //#endregion

  //#region 'Add Multiple QA Dates'
  public openQADateDialog(log?: WeldLog) {
    if (log) {
      this.qaDateDialogHeader = 'Add QA Dates to Multiple Weld Logs';
      this.currentLog = log;
    } else {
      this.qaDateDialogHeader = 'Add QA Dates to Multiple Weld Logs';
    }
    this.displayEditQADateDialog = true;
  }

  public closeQADateDialog() {
    this.displayEditQADateDialog = false;
  }

  //#endregion

  //#region 'Logic for Adding Multiple QA Types and Add Multiple QA Dates'
  public async editMultipleQATypesAndDates() {
    this.editMultipleQATypeVals = this.editMultipleQATypesComponent.qaTypeInformation;
    const showCalendar = this.editMultipleQATypesComponent.showCalendar; //calendar is for QA Date Types

    // Check if the user has filled out the required fields
    if (!this.isValidQATypeAndDateInfo(this.editMultipleQATypeVals, showCalendar)) {
      this.showNotification(
        new NotificationToast('error', 'Error: Please select all the required values!', '', 'error', 6000)
      );
      return;
    }

    this._loader.show();

    this.selectedLogs.forEach(log => {
      log.divisionId = log.original.divisionId;
      log.comments = log.original.comments;
      log.jointTypeId = log.original.jointTypeId;
      log.materialFrom = log.original.materialFrom;
      log.materialFromId = log.original.materialFromId;
      log.materialTo = log.original.materialTo;
      log.materialToId = log.original.materialToId;
      log.refineryName = log.original.refineryName;
      log.rtpercentageId = log.original.rtpercentageId;
      log.rtPercentage = log.original.rtPercentage;
      log.scheduleId = log.original.scheduleId;
      log.scheduleName = log.original.scheduleName;
      log.sizeId = log.original.sizeId;
      log.wallThickness = log.original.wallThickness;
      log.unitId = log.original.unitId;
      log.weldGroupId = log.original.weldGroupId;
    });

    const toSubmit = this.prepareSubmission(this.editMultipleQATypeVals, this.selectedLogs);

    if (toSubmit.length === 0) {
      this._loader.hide();
      if (showCalendar == 1) {
        this.showNotification(
          new NotificationToast(
            'error',
            "Error: The QA Type already contains dates in all the selected logs or doesn't exist in either of selected logs.",
            '',
            'error',
            10000
          )
        );
      } else {
        this.showNotification(
          new NotificationToast(
            'error',
            'Error: The selected QA Types already exist in all of the selected logs!',
            '',
            'error',
            6000
          )
        );
      }
      return;
    }

    this.logService.UpdateMultipleWeldLogs(toSubmit).then(res => {
      this.multipleLogsSuccessOrFail(res, 'update');
      this.displayEditQATypeDialog = false;
      this.displayEditQADateDialog = false;
      this._loader.hide();
    });
  }

  //sees if the user has filled out the required fields
  private isValidQATypeAndDateInfo(info, showCalendar) {
    if (
      info == undefined ||
      info.length == 0 ||
      info[0]['qaTypeID'] == undefined ||
      info[0]['qaTypeLabelID'] == undefined
    ) {
      return false;
    }

    if (showCalendar) {
      if (info[0].qaDate[0] == null) {
        return false;
      }
    }

    return true;
  }

  private prepareSubmission(QAInformation, selectedLogs) {
    let toSubmit = [];
    if (this.editMultipleQATypesComponent.showCalendar == 1) {
      for (let currentSelectedLog of selectedLogs) {
        if (!this.hasValidTypeAndDateFields(currentSelectedLog)) {
          continue;
        } else {
          let completedLog = this.logService.editQADate(QAInformation, currentSelectedLog);
          toSubmit.push(completedLog);
        }
      }
    } else {
      for (let currentSelectedLog of selectedLogs) {
        if (!this.hasUniqueQAFields(currentSelectedLog)) {
          continue;
        }

        let completedLog = this.logService.processQATypes(QAInformation, currentSelectedLog, true);
        toSubmit.push(completedLog);
      }
    }

    return toSubmit;
  }

  private hasUniqueQAFields(currentSelectedLog: any) {
    if (!currentSelectedLog.weldLogQAs || currentSelectedLog.weldLogQAs.length === 0) {
      return false;
    }

    for (let currentElementInQAList of currentSelectedLog.weldLogQAs) {
      if (
        !currentElementInQAList ||
        currentElementInQAList === undefined ||
        currentElementInQAList.qaTypeID === undefined ||
        currentElementInQAList.qaTypeLabelID === undefined
      ) {
        return false;
      }

      if (currentElementInQAList.qaTypeID == this.editMultipleQATypeVals[0]['qaTypeID']) {
        return false;
      }
    }
    return true;
  }

  private hasValidTypeAndDateFields(currentSelectedLog: WeldLog) {
    if (!currentSelectedLog.weldLogQAs) {
      return false;
    }

    for (let currWeldLogArray of currentSelectedLog.weldLogQAs) {
      // If QA Type already exists
      // and If the QA Type Label Matches && date is already there, skip
      // If the QA Type Label Matches && date == null, replace it
      if (
        currWeldLogArray.qaTypeID === this.editMultipleQATypeVals[0].qaTypeID &&
        currWeldLogArray.qaTypeLabelID === this.editMultipleQATypeVals[0].qaTypeLabelID
      ) {
        return currWeldLogArray.qaDate == null;
      }
    }

    //If QA Type doesn't exist, skip it
    return false;
  }
  //#endregion

  //#region 'Add Multiple Stamp Processes'
  public openProcessDialog(log?: WeldLog) {
    if (log) {
      this.processDialogHeader = 'Add Welder to Multiple Weld Logs';
      this.currentLog = log;
    } else {
      this.processDialogHeader = 'Add Welder to Multiple Weld Logs';
    }
    this.displayEditProcessDialog = true;
  }

  public closeProcessDialog() {
    this.displayEditProcessDialog = false;
  }
  //#endregion

  //#region 'Add Multiple Accept/Reject'
  public openAcceptRejectDialog(log?: WeldLog) {
    if (log) {
      this.acceptRejectDialogHeader = 'Add Accept/Reject to Multiple Weld Logs';
      this.currentLog = log;
    } else {
      this.acceptRejectDialogHeader = 'Add Accept/Reject to Multiple Weld Logs';
    }
    this.displayEditAcceptRejectDialog = true;
  }

  public closeAcceptRejectDialog() {
    this.displayEditAcceptRejectDialog = false;
  }

  //#endregion

  //#region 'Logic for Adding Welders and Processes to Multiple Logs'
  public async editMultipleProcesses() {
    this.editMultipleProcessesVals = this.editMultipleProcessesComponent.stampProcessInformation;
    const showAcceptReject = this.editMultipleProcessesComponent.showAcceptReject; //Apply Accept/Reject to Welders

    //Check if the user has filled out the required fields
    if (!this.isValidProcessInfo(this.editMultipleProcessesVals, showAcceptReject)) {
      this.showNotification(
        new NotificationToast('error', 'Error: Please select all the required values!', '', 'error', 6000)
      );
      return;
    }

    this.prepareMultipleStampProcess(this.editMultipleProcessesVals, this.selectedLogs);
    if (this.pendingSubmissions.length > 0) {
      this.showConfirm('process');
    } else {
      this.submitMultipleStampProcess();
    }
  }

  // Check if the user has filled out the required fields
  private isValidProcessInfo(info, showAcceptReject) {
    if (info == undefined || info.length == 0) {
      return false;
    }

    if (!showAcceptReject && (info[0]['stampid'] == null || info[0]['processids'].length == 0)) {
      return false;
    }

    if (showAcceptReject && info[0]['rtacceptedRejected'] == null) {
      return false;
    }

    return true;
  }

  private prepareMultipleStampProcess(processInformation: any[], selectedLogs: WeldLog[]) {
    this.confirmedSubmissions = [];
    this.skippedSubmissions = [];
    this.pendingSubmissions = [];

    // Prepare Accept/Reject only
    if (this.editMultipleProcessesComponent.showAcceptReject == 1) {
      for (let currentSelectedLog of selectedLogs) {
        if (this.validateAcceptReject(currentSelectedLog, processInformation)) {
          //Add/Update process information, then complete log
          for (let weldLogStampProcess of currentSelectedLog.weldLogStampProcesses) {
            weldLogStampProcess.rtacceptedRejected = processInformation[0].rtacceptedRejected;
          }
          let completedLog = currentSelectedLog;
          this.confirmedSubmissions.push(completedLog);
        }
      }
    } else {
      // Prepare Stamp Process only
      for (let currentSelectedLog of selectedLogs) {
        if (this.validateStampProcess(currentSelectedLog, processInformation)) {
          let completedLog = this.logService.processStamp(processInformation, currentSelectedLog, true);
          this.confirmedSubmissions.push(completedLog);
        }
      }
    }
  }

  private validateAcceptReject(currentSelectedLog: WeldLog, processInformation: any[]) {
    //Cannot add accept/reject to welds with no welder
    if (!currentSelectedLog.weldLogStampProcesses || currentSelectedLog.weldLogStampProcesses.length === 0) {
      this.skippedSubmissions.push(currentSelectedLog);
      return false;
    }

    //Cannot add accept/reject to welds where status already exists
    for (let weldLogStampProcess of currentSelectedLog.weldLogStampProcesses) {
      if (weldLogStampProcess.rtacceptedRejected == processInformation[0].rtacceptedRejected) {
        this.skippedSubmissions.push(currentSelectedLog);
        return false;
      }

      //Cannot add accept/reject to welds with multiple welders
      for (let compare of currentSelectedLog.weldLogStampProcesses) {
        if (weldLogStampProcess.stampAllocationId != compare.stampAllocationId) {
          this.skippedSubmissions.push(currentSelectedLog);
          return false;
        }
      }
    }
    return true;
  }

  private validateStampProcess(currentSelectedLog: WeldLog, processInformation: any[]) {
    //Can add welder & process to logs where no welder already exists
    if (!currentSelectedLog.weldLogStampProcesses || currentSelectedLog.weldLogStampProcesses.length === 0) {
      return true;
    }

    //Can add a welder & process to logs where the same welder already exists
    let hasDifferentWelder = false;
    for (let weldLogStampProcess of currentSelectedLog.weldLogStampProcesses) {
      if (weldLogStampProcess.stampAllocationId != processInformation[0]['stampid']) {
        hasDifferentWelder = true;
      }

      //Check and ignore any selected processes that already exists
      let index = processInformation[0]['processids'].indexOf(weldLogStampProcess.weldProcessId);
      if (index > -1) {
        processInformation[0]['processids'].splice(index, 1);
      }
    }

    if (!hasDifferentWelder && processInformation[0]['processids'].length > 0) {
      //Update existing process information, then complete log
      for (let weldLogStampProcess of currentSelectedLog.weldLogStampProcesses) {
        weldLogStampProcess.rtacceptedRejected = processInformation[0].rtacceptedRejected;
      }
      let completedLog = this.logService.processStamp(processInformation, currentSelectedLog, true);
      this.pendingSubmissions.push(completedLog);
      return false;
    }

    //Cannot add a different welder or the same welder with the same processes
    this.skippedSubmissions.push(currentSelectedLog);
    return false;
  }

  private submitMultipleStampProcess() {
    const showAcceptReject = this.editMultipleProcessesComponent.showAcceptReject; //Apply Accept/Reject to Welders

    if (this.bypassWarning) {
      this.confirmedSubmissions.push(...this.pendingSubmissions);
    }

    this._loader.show();

    this.selectedLogs.forEach(log => {
      log.divisionId = log.original.divisionId;
      log.comments = log.original.comments;
      log.jointTypeId = log.original.jointTypeId;
      log.materialFrom = log.original.materialFrom;
      log.materialFromId = log.original.materialFromId;
      log.materialTo = log.original.materialTo;
      log.materialToId = log.original.materialToId;
      log.refineryName = log.original.refineryName;
      log.rtpercentageId = log.original.rtpercentageId;
      log.rtPercentage = log.original.rtPercentage;
      log.scheduleId = log.original.scheduleId;
      log.scheduleName = log.original.scheduleName;
      log.sizeId = log.original.sizeId;
      log.wallThickness = log.original.wallThickness;
      log.unitId = log.original.unitId;
      log.weldGroupId = log.original.weldGroupId;
    });

    if (this.confirmedSubmissions.length === 0) {
      this._loader.hide();
      if (showAcceptReject == 1) {
        this.showNotification(
          new NotificationToast(
            'error',
            'Error: All of the selected weld logs either have no welder, multiple welders, or status is already selected',
            '',
            'error',
            10000
          )
        );
      } else {
        this.showNotification(
          new NotificationToast(
            'error',
            'Error: All of the selected weld logs already contain a different welder or the same welder and process.',
            '',
            'error',
            10000
          )
        );
      }
      return;
    }

    this.logService.UpdateMultipleWeldLogs(this.confirmedSubmissions).then(res => {
      this.multipleLogsSuccessOrFail(res, 'update');
      this.displayEditProcessDialog = false;
      this.displayEditAcceptRejectDialog = false;
      this._loader.hide();
    });

    if (this.skippedSubmissions.length > 0 && showAcceptReject) {
      this.showLogsSkippedNotification('no welders or multiple welders');
    } else if (this.skippedSubmissions.length > 0 && !showAcceptReject) {
      this.showLogsSkippedNotification('different welder(s) or welder already exists with the same processes');
    }
  }

  private showLogsSkippedNotification(message) {
    let failedWeldNos = '';
    let numOfFails = 0;
    for (let log of this.skippedSubmissions) {
      failedWeldNos += `${log.weldNo}, `;
      numOfFails += 1;
    }
    this._notify.add({
      severity: 'warn',
      summary: `Error updating the following (${numOfFails}) logs`,
      detail: `Weld #'s: ${failedWeldNos} contain ${message}`,
      key: 'error',
      sticky: true
    });
  }
  //#endregion

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._notifSrv.sendNotification(msg);
  }

  private showConfirm(str: string) {
    this.confirmType = str;
    this._notify.clear();
    switch (this.confirmType) {
      case 'edit':
        this._notify.add({
          key: 'confirm',
          sticky: true,
          severity: 'warn',
          summary: `Continuity Logs will not be updated with 'Edit Multiple Logs'.`,
          detail: 'Confirm to proceed'
        });
        break;
      case 'delete':
        this._notify.add({
          key: 'confirm',
          sticky: true,
          severity: 'warn',
          summary: `Continuity Logs will not be deleted with 'Delete Multiple Logs'.`,
          detail: 'Confirm to proceed'
        });
        break;
      case 'process':
        this._notify.add({
          key: 'confirm',
          sticky: true,
          severity: 'warn',
          summary: `Welder already exists with a different process.`,
          detail: 'Confirm to add new process to welder or Cancel to abort.'
        });
        break;
    }
  }

  public onConfirm() {
    this._notify.clear();
    this.selectedLogs = this.selectedLogs.filter(log => log.weldType === 1);
    switch (this.confirmType) {
      case 'edit':
        this.openEditMultipleLogsForm();
        break;
      case 'delete':
        this.openDeleteDialog();
        break;
      case 'process':
        this.bypassWarning = true;
        this.submitMultipleStampProcess(); //meaning they saw the warning and still want to submit
        break;
    }
  }

  public onReject() {
    this._notify.clear();
  }
  //#endregion 'Notification'

  //#region 'Export to Excel'
  public exportToExcel() {
    let tempCols = [...this.defaultHeaders, ...this.optionalHeaders];
    this._expExcel.weldLogTableToExcel(tempCols, this.weldLogData, this.activeFilterParams);
  }
  //#endregion 'Export to Excel'
}
